<template>
  <div class="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex" class="tabs" @on-click="onClickTabs">
        <TabPane
          v-for="item in tabArr"
          :key="item.name"
          :label="item.name + (item.number ? '(' + item.number + ')' : '')"
        >
        </TabPane>
        <!-- <Icon custom="i-icon icon-shezhi"
              size="24"
              class="cursor-pointer"
              @click.stop="onClickSet"
              slot="extra"></Icon> -->
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <!--车辆作业日志菜单栏的搜索栏 -->
          <div class="left">
            <template v-if="currentTabIndex == 0">
              <Select
                v-model="remoteGrid"
                filterable
                clearable
                placeholder="作业网格"
                :remote-method="remoteGridMethod"
                :loading="remoteGridLoading"
                class="m-r-10"
                style="width: 160px"
                @on-query-change="onQueryChangeRemoteGrid"
              >
                <Option
                  v-for="(option, index) in remoteGridList"
                  :value="option.gridId"
                  :key="option.gridId"
                  >{{ option.name }}</Option
                >
              </Select>
              <Select
                v-model="remoteCar"
                filterable
                clearable
                placeholder="请输入车牌号"
                :remote-method="remoteMethod"
                :loading="remoteCarLoading"
                class="m-r-10"
                style="width: 160px"
                @on-query-change="onQueryChangeRemoteCar"
              >
                <Option
                  v-for="(option, index) in remoteCarList"
                  :value="option.id"
                  :key="option.id"
                  >{{ option.name }}</Option
                >
              </Select>
              <Select
                v-model="remoteName"
                filterable
                clearable
                placeholder="提交人"
                :remote-method="remoteNameMethod"
                :loading="remoteNameLoading"
                class="m-r-10"
                style="width: 160px"
                @on-query-change="onQueryChangeRemoteName"
              >
                <Option
                  v-for="(option, index) in remoteNameList"
                  :value="option.uid"
                  :key="option.uid"
                  >{{ option.name }}</Option
                >
              </Select>
              <DatePicker
                :value="date"
                type="daterange"
                :start-date="new Date()"
                @on-change="onChangeDate"
                style="width: 260px"
              ></DatePicker>
            </template>
            <!--人员作业日志菜单栏的搜索栏 -->
            <template v-if="currentTabIndex == 1">
              <Select
                v-model="remoteGrid"
                filterable
                clearable
                placeholder="作业网格"
                :remote-method="remoteGridMethod"
                :loading="remoteGridLoading"
                class="m-r-10"
                style="width: 160px"
                @on-query-change="onQueryChangeRemoteGrid"
              >
                <Option
                  v-for="(option, index) in remoteGridList"
                  :value="option.gridId"
                  :key="option.gridId"
                  >{{ option.name }}</Option
                >
              </Select>
              <Input
                clearable
                placeholder="作业人员"
                class="m-r-10"
                style="width: 160px"
              />
              <Select
                v-model="remoteName"
                filterable
                clearable
                placeholder="提交人"
                :remote-method="remoteNameMethod"
                :loading="remoteNameLoading"
                class="m-r-10"
                style="width: 160px"
                @on-query-change="onQueryChangeRemoteName"
              >
                <Option
                  v-for="(option, index) in remoteNameList"
                  :value="option.uid"
                  :key="option.uid"
                  >{{ option.name }}</Option
                >
              </Select>
              <DatePicker
                type="daterange"
                :start-date="new Date()"
                @on-change="onChangeDate"
                style="width: 260px"
              ></DatePicker>
            </template>
            <!--园林作业日志菜单栏的搜索栏 -->
            <template v-if="currentTabIndex == 2">
              <Select v-model="questBigType"
                placeholder="园林类型"
                clearable
                @on-change="onChangeBigType"
                class="m-r-10"
                style="width: 160px"
                
              >
                <Option
                  v-for="item in questBigTypeArr"
                  :value="item.id"
                  :key="item.id"
                  >{{ item.name }}</Option
                >
              </Select>
              <Input
                clearable
                placeholder="园林名称"
                class="m-r-10"
                style="width: 160px"
              />
              <Select
                v-model="remoteName"
                filterable
                clearable
                placeholder="提交人"
                :remote-method="remoteNameMethod"
                :loading="remoteNameLoading"
                class="m-r-10"
                style="width: 160px"
                @on-query-change="onQueryChangeRemoteName"
              >
                <Option
                  v-for="(option, index) in remoteNameList"
                  :value="option.uid"
                  :key="option.uid"
                  >{{ option.name }}</Option
                >
              </Select>
              <DatePicker
                type="daterange"
                :start-date="new Date()"
                @on-change="onChangeDate"
                style="width: 260px"
              ></DatePicker>
            </template>
            <!--古树作业日志菜单栏的搜索栏 -->
            <template v-if="currentTabIndex == 3">
              <Select
                placeholder="古树类型"
                clearable
                class="m-r-10"
                style="width: 160px"
              >
                <Option
                  v-for="item in questBigTypeArr"
                  :value="item.id"
                  :key="item.id"
                  >{{ item.name }}</Option
                >
              </Select>
              <Input
                clearable
                placeholder="古树名称"
                class="m-r-10"
                style="width: 160px"
              />
              <Select
                v-model="remoteName"
                filterable
                clearable
                placeholder="提交人"
                :remote-method="remoteNameMethod"
                :loading="remoteNameLoading"
                class="m-r-10"
                style="width: 160px"
                @on-query-change="onQueryChangeRemoteName"
              >
                <Option
                  v-for="(option, index) in remoteNameList"
                  :value="option.uid"
                  :key="option.uid"
                  >{{ option.name }}</Option
                >
              </Select>
              <DatePicker
                type="daterange"
                :start-date="new Date()"
                @on-change="onChangeDate"
                style="width: 260px"
              ></DatePicker>
            </template>
            <!--设施作业日志菜单栏的搜索栏 -->
            <template v-if="currentTabIndex == 4">
              <Select clearable class="m-r-10" style="width: 160px">
                <Option
                  v-for="item in questBigTypeArr"
                  :value="item.id"
                  :key="item.id"
                  >{{ item.name }}</Option
                >
              </Select>
              <Input
                clearable
                placeholder="设施名称"
                class="m-r-10"
                style="width: 160px"
              />
              <Select
                v-model="remoteName"
                filterable
                clearable
                placeholder="提交人"
                :remote-method="remoteNameMethod"
                :loading="remoteNameLoading"
                class="m-r-10"
                style="width: 160px"
                @on-query-change="onQueryChangeRemoteName"
              >
                <Option
                  v-for="(option, index) in remoteNameList"
                  :value="option.uid"
                  :key="option.uid"
                  >{{ option.name }}</Option
                >
              </Select>
              <DatePicker
                type="daterange"
                :start-date="new Date()"
                @on-change="onChangeDate"
                style="width: 260px"
              ></DatePicker>
            </template>
          </div>
          <!-- 查询搜索栏 -->
          <div class="right">
            <Button type="primary" class="m-l-10" @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo" size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10" @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin" size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex">
        <Button class="m-r-10">
          <Icon custom="i-icon icon-shangchuan" size="16"></Icon>
          导出
        </Button>
      </div>
      <div class="table">
        <Table
          ref="table"
          :height="tableHeight"
          :columns="getColumns"
          :data="data"
          border
          stripe
          :loading="tableLoading"
          @on-selection-change="onChangeSelect"
          @on-sort-change="onChangeSort"
        >
          <template slot-scope="{ row }" slot="video">
            <div
              v-previewVideo="row.videourl"
              style="
                width: 24px;
                height: 24px;
                background: #797979;
                margin: auto;
                cursor: pointer;
              "
              v-if="row.videourl"
            >
              <Icon custom="i-icon icon-bofang" size="16" color="#fff"></Icon>
            </div>
          </template>
          <template slot-scope="{ row }" slot="operation"> </template>
        </Table>
        <Page
          class="m-t-10 m-l-10"
          :total="totalPage"
          :current="pageNum"
          :page-size="pageSize"
          show-total
          show-sizer
          show-elevator
          @on-change="onPageChange"
          @on-page-size-change="onPageSizeChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { throttle } from "@/utils/debounceAndThrottle";
export default {
  // 组件
  components: {},
  // 变量
  // 填写数据data
  data() {
    return {
      currentTabIndex: 0,
      buttonIndex: null,
      tabArr: [
        {
          name: "车辆作业日志",
          number: 0,
        },
        {
          name: "人员作业日志",
          number: 0,
        },
        {
          name: "园林作业日志",
          number: 0,
        },
        {
          name: "古树作业日志",
          number: 0,
        },
        {
          name: "设施作业日志",
          number: 0,
        },
      ],
      // 表格相关 start
      totalPage: 0,
      pageNum: 1, // 页数
      pageSize: 20, // 每页几条数据
      tableHeight: 0,
      tableLoading: false,
      // 表格相关 end
      questBigType:"",
      questBigTypeArr: [],
      // 作业车辆
      remoteCar: "",
      remoteCarList: [],
      remoteCarLoading: false,
      //作业网格
      remoteGrid: "",
      remoteGridList: [],
      remoteGridLoading: false,
      // 提交人
      remoteName: "",
      remoteNameList: [],
      remoteNameLoading: false,
      // 选择时间
      date: "",
      
     
      // 列表star
      carworklogColumns: [
        {
          type: "selection",
          width: 45,
          align: "center",
        },
        {
          title: "ID",
          width: 45,
          align: "center",
          render: (h, params) => {
            let index = params.index + 1;
            let num = (this.pageNum - 1) * this.pageSize + index;
            return h("span", num);
          },
        },
        {
          title: "提交人",
          key: "submitter",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "所属机构",
          key: "depname",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "日志内容",
          key: "logContent",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "日志图片",
          key: "imgurls",
          width: 100,
          tooltip: true,
          align: "center",
          render: (h, params) => {
            let row = params.row;
            if (row.imgurls) {
              let arr = row.imgurls.split(",");
              console.log(arr);
              if (arr.length) {
                return h("el-image", {
                  props: {
                    src: arr[0],
                    "preview-src-list": arr,
                  },
                  style: {
                    width: "22px",
                    height: "22px",
                    cursor: "pointer",
                  },
                });
              }
            }
            return h("span", "");
          },
        },
        {
          title: "日志视频",
          key: "videourl",
          width: 100,
          tooltip: true,
          align: "center",
          slot: "video",
        },
        {
          title: "作业网络",
          key: "jobGrid",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "作业车辆",
          key: "jobCar",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "作业类型",
          key: "jobType",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "提交时间",
          key: "submitTime",
          width: 180,
          tooltip: true,
          sortable: "custom",
          sortType: "desc",
          align: "center",
        },
        {
          title: "操作",
          slot: "operation",
          width: 210,
          align: "center",
        },
      ],
      // 列表end
      staffworklogColumns: [
        {
          type: "selection",
          width: 45,
          align: "center",
        },
        {
          title: "ID",
          width: 45,
          align: "center",
          render: (h, params) => {
            let index = params.index + 1;
            let num = (this.pageNum - 1) * this.pageSize + index;
            return h("span", num);
          },
        },
        {
          title: "提交人",
          key: "submitter",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "所属机构",
          key: "depname",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "日志内容",
          key: "logContent",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "日志图片",
          key: "imgurls",
          minWidth: 60,
          tooltip: true,
          align: "center",
          render: (h, params) => {
            let row = params.row;
            if (row.imgurls) {
              let arr = row.imgurls.split(",");
              console.log(arr);
              if (arr.length) {
                return h("el-image", {
                  props: {
                    src: arr[0],
                    "preview-src-list": arr,
                  },
                  style: {
                    width: "22px",
                    height: "22px",
                    cursor: "pointer",
                  },
                });
              }
            }
            return h("span", "");
          },
        },
        {
          title: "日志视频",
          key: "videourl",
          minWidth: 50,
          tooltip: true,
          align: "center",
          slot: "video",
        },
        {
          title: "作业网格",
          key: "jobGrid",
          minWidth: 50,
          tooltip: true,
          align: "center",
          slot: "video",
        },
        {
          title: "作业人员",
          key: "jobPerson",
          minWidth: 50,
          tooltip: true,
          align: "center",
        },
        {
          title: "作业类型",
          key: "jobType",
          minWidth: 50,
          tooltip: true,
          align: "center",
        },
        {
          title: "提交时间",
          key: "submitTime",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "操作",
          slot: "operation",
          width: 210,
          align: "center",
        },
      ],
      gardenworklogColumns: [
        {
          type: "selection",
          width: 45,
          align: "center",
        },
        {
          title: "ID",
          width: 45,
          align: "center",
          render: (h, params) => {
            let index = params.index + 1;
            let num = (this.pageNum - 1) * this.pageSize + index;
            return h("span", num);
          },
        },
        {
          title: "提交人",
          key: "submitter",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "所属机构",
          key: "depname",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "日志内容",
          key: "logContent",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "日志图片",
          key: "imgurls",
          minWidth: 60,
          tooltip: true,
          align: "center",
          render: (h, params) => {
            let row = params.row;
            if (row.imgurls) {
              let arr = row.imgurls.split(",");
              console.log(arr);
              if (arr.length) {
                return h("el-image", {
                  props: {
                    src: arr[0],
                    "preview-src-list": arr,
                  },
                  style: {
                    width: "22px",
                    height: "22px",
                    cursor: "pointer",
                  },
                });
              }
            }
            return h("span", "");
          },
        },
        {
          title: "日志视频",
          key: "videourl",
          minWidth: 50,
          tooltip: true,
          align: "center",
          slot: "video",
        },
        {
          title: "园林名称",
          key: "garName",
          minWidth: 50,
          tooltip: true,
          align: "center",
          slot: "video",
        },
        {
          title: "提交时间",
          key: "submitTime",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "操作",
          slot: "operation",
          width: 210,
          align: "center",
        },
      ],
      treeworklogColumns: [
        {
          type: "selection",
          width: 45,
          align: "center",
        },
        {
          title: "ID",
          width: 45,
          align: "center",
          render: (h, params) => {
            let index = params.index + 1;
            let num = (this.pageNum - 1) * this.pageSize + index;
            return h("span", num);
          },
        },
        {
          title: "提交人",
          key: "submitter",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "所属机构",
          key: "depname",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "日志内容",
          key: "logContent",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "日志图片",
          key: "imgurls",
          minWidth: 60,
          tooltip: true,
          align: "center",
          render: (h, params) => {
            let row = params.row;
            if (row.imgurls) {
              let arr = row.imgurls.split(",");
              console.log(arr);
              if (arr.length) {
                return h("el-image", {
                  props: {
                    src: arr[0],
                    "preview-src-list": arr,
                  },
                  style: {
                    width: "22px",
                    height: "22px",
                    cursor: "pointer",
                  },
                });
              }
            }
            return h("span", "");
          },
        },
        {
          title: "日志视频",
          key: "videourl",
          minWidth: 50,
          tooltip: true,
          align: "center",
          slot: "video",
        },
        {
          title: "古树名称",
          key: "treeName",
          minWidth: 50,
          tooltip: true,
          align: "center",
          slot: "video",
        },
        {
          title: "提交时间",
          key: "submitTime",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "操作",
          slot: "operation",
          width: 210,
          align: "center",
        },
      ],
      facilitiesworklogColumns: [
        {
          type: "selection",
          width: 45,
          align: "center",
        },
        {
          title: "ID",
          width: 45,
          align: "center",
          render: (h, params) => {
            let index = params.index + 1;
            let num = (this.pageNum - 1) * this.pageSize + index;
            return h("span", num);
          },
        },
        {
          title: "提交人",
          key: "submitter",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "所属机构",
          key: "depname",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "日志内容",
          key: "logContent",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "日志图片",
          key: "imgurls",
          minWidth: 60,
          tooltip: true,
          align: "center",
          render: (h, params) => {
            let row = params.row;
            if (row.imgurls) {
              let arr = row.imgurls.split(",");
              console.log(arr);
              if (arr.length) {
                return h("el-image", {
                  props: {
                    src: arr[0],
                    "preview-src-list": arr,
                  },
                  style: {
                    width: "22px",
                    height: "22px",
                    cursor: "pointer",
                  },
                });
              }
            }
            return h("span", "");
          },
        },
        {
          title: "日志视频",
          key: "videourl",
          minWidth: 50,
          tooltip: true,
          align: "center",
          slot: "video",
        },
        {
          title: "设施名称",
          key: "videoUrl",
          minWidth: 50,
          tooltip: true,
          align: "center",
          slot: "video",
        },
        {
          title: "提交时间",
          key: "submitTime",
          minWidth: 60,
          tooltip: true,
          align: "center",
        },
        {
          title: "操作",
          slot: "operation",
          width: 210,
          align: "center",
        },
      ],
      data: [],
      selectArr: [],
    };
  },
  watch: {
    
  },
  computed: {
    getColumns() {
      let columns = [];
      switch (this.currentTabIndex) {
        case 0:
          columns = this.carworklogColumns;
          break;
        case 1:
          columns = this.staffworklogColumns;
          break;
        case 2:
          columns = this.gardenworklogColumns;
          break;
        case 3:
          columns = this.treeworklogColumns;
          break;
        case 4:
          columns = this.facilitiesworklogColumns;
          break;
        default:
          break;
      }
      return columns;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.tableHeight = window.innerHeight - 390;
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 390;
      };
      this.getList();
    },
    // 点击切换tab
    onClickTabs() {
      this.resetSearchData();
      this.onClickSearch();
    },
    // 车牌号远程搜索
    remoteMethod: throttle(function (query) {
      if (query !== "") {
        this.remoteCarLoading = true;
        let params = {
          onlineStatus: "",
          type: "",
          itemType: "",
          name: query,
          company: "",
        };
        this.$http.getMapSearchCarList(params).then((res) => {
          if (res.code === 200) {
            this.remoteCarLoading = false;
            let data = res.result;
            this.remoteCarList = data.filter(
              (item) =>
                item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
            );
          } else {
            this.$Message.info(res.message);
            this.remoteCarLoading = false;
            this.remoteCarList = [];
          }
        });
      } else {
        this.remoteCarList = [];
      }
    }),
    // 车牌号远程搜索框内容改变时
    onQueryChangeRemoteCar(value) {
      if (!value) {
        this.remoteCarList = [];
      }
    },
    // 网格远程搜索
    remoteGridMethod: throttle(function (query) {
      if (query !== "") {
        this.remoteCarLoading = true;
        let params = {
          page: 1,
          size: 99999,
          type: "",
          name: query,
          depid: "",
        };
        this.$http.getGridList(params).then((res) => {
          if (res.code === 200) {
            this.remoteGridLoading = false;
            let data = res.result.grids;
            this.remoteGridList = data.filter(
              (item) =>
                item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
            );
          } else {
            this.$Message.info(res.message);
            this.remoteGridLoading = false;
            this.remoteGridList = [];
          }
        });
      } else {
        this.remoteGridList = [];
      }
    }),
    // 网格远程搜索框内容改变时
    onQueryChangeRemoteGrid(value) {
      if (!value) {
        this.remoteGridList = [];
      }
    },
    // 责任人远程搜索
    remoteNameMethod: throttle(function (query) {
      if (query !== "") {
        this.remoteNameLoading = true;
        let params = {
          page: 1,
          size: 99999,
          name: query,
        };
        this.$http.getUserList(params).then((res) => {
          if (res.code === 200) {
            this.remoteNameLoading = false;
            let data = res.result.users;
            this.remoteNameList = data.filter(
              (item) =>
                item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
            );
          } else {
            this.$Message.info(res.message);
            this.remoteNameLoading = false;
            this.remoteNameList = [];
          }
        });
      } else {
        this.remoteNameList = [];
      }
    }),
    // 提交人远程搜索框内容改变时
    onQueryChangeRemoteName(value) {
      if (!value) {
        this.remoteNameList = [];
      }
    },
    onChangeDate(date) {
      this.date = date;
    },
    onChangeSelect(selection) {
      this.selectArr = selection.map((item) => item.id);
    },
    onChangeSort() {
      this.onClickSearch();
    },
    // 页数改变
    onPageChange(event) {
      this.pageNum = event;
      this.getList();
    },
    // 每页条数改变
    onPageSizeChange(event) {
      this.pageSize = event;
      this.getList();
    },
    // 查询
    onClickSearch() {
      this.pageNum = 1;
      this.getList();
    },
    // 点击重置
    onClickReset() {
      this.resetSearchData();
      this.onClickSearch();
    },
    // 重置搜索条件
    resetSearchData() {
      this.remoteCar = "";
      this.onChangeBigType()
    },
    // 对接口地方getList
    getList() {
      switch (this.currentTabIndex) {
        case 0:
          this.getCarWorkLogList();
          break;
        case 1:
          this.getStaffWorkLogList();
          break;
        case 2:
          this.getGardenWorkLogList();
          break;
        case 3:
          this.getTreeWorkLogList();
          break;
        case 4:
          this.getFacilitiesWorkLogList();
          break;
        default:
          break;
      }
    },
    // 车辆日志
    getCarWorkLogList() {
      console.log(this.remoteCar);
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        costType: "",
        deptId: "",
        endTime: this.date[1],
        gridId: this.remoteGrid,
        ids: [],
        startTime: this.date[0],
        subjectId: this.remoteCar,
        userId: this.remoteName,
      };
      this.tableLoading = true;
      this.$http
        .getCarWorkLogList(params)
        .then((res) => {
          this.tableLoading = false;
          if (res.code === 200) {
            this.totalPage = res.result.total;
            this.data = res.result.rows;
          }
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    // 人员作业日志
    getStaffWorkLogList() {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        costType: "",
        deptId: "",
        endTime: this.date[1],
        gridId: this.remoteGrid,
        ids: [],
        startTime: this.date[0],
        subjectId: "",
        userId: this.remoteName,
      };
      this.tableLoading = true;
      this.$http
        .getStaffWorkLogList(params)
        .then((res) => {
          this.tableLoading = false;
          if (res.code === 200) {
            this.totalPage = res.result.total;
            this.data = res.result.rows;
          }
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    // 园林作业日志
    getGardenWorkLogList() {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        endTime: this.date[1],
        startTime: this.date[0],

        userId: this.remoteName,
        treeType: "",
        subjectName: "",
        logType: "",
      };
      this.tableLoading = true;
      this.$http
        .getGardenWorkLogList(params)
        .then((res) => {
          this.tableLoading = false;
          if (res.code === 200) {
            this.totalPage = res.result.total;
            this.data = res.result.rows;
          }
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    // 古树作业日志
    getTreeWorkLogList() {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        endTime:this.date[1],
        startTime: this.date[0],
        userId: this.remoteName,
      };
      this.tableLoading = true;
      this.$http
        .getGardenWorkLogList(params)
        .then((res) => {
          this.tableLoading = false;
          if (res.code === 200) {
            this.totalPage = res.result.total;
            this.data = res.result.rows;
          }
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    // 设施作业日志
    getFacilitiesWorkLogList() {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        endTime: this.date[1],
        startTime: this.date[0],
        userId: this.remoteName,
      };
      this.tableLoading = true;
      this.$http
        .getGardenWorkLogList(params)
        .then((res) => {
          this.tableLoading = false;
          if (res.code === 200) {
            this.totalPage = res.result.total;
            this.data = res.result.rows;
          }
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    // 点击查看地址
    onClickCaseAddress(row) {
      this.positionAddress = row.address;
      this.positionLng = row.lng;
      this.positionLat = row.lat;
      this.positionDetailVisible = true;
    },
  },
};
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;

  .topbar {
    background-color: #fff;
    border: 2px solid #fff;
    .topbar-search {
      padding: 16px;
    }
  }

  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;

    .table {
      margin-top: 16px;
    }
  }
}
</style>
